<template>
  <div class="dr-container">
    <div class="dr-title unselect" @click="toggle=!toggle">
      <div class="sub3 ellipsis" style="flex:1">
        <span class="pc body2 ellipsis-1">{{ title }}</span><span class="mobile body5 ellipsis-1">{{ title }}</span>
      </div>
      <img src="/static/icon/fi_chevron-up.svg" style="width:20px;height:20px" v-if="toggle">
      <img src="/static/icon/fi_chevron-down.svg" style="width:20px;height:20px" v-else>
    </div>
    <div v-if="toggle" class="dr-content">
      <div v-for="(item,idx) in list" :key="'dr-item-'+idx" class="dr-item">
        <div class="sub3 ellipsis-1" style="flex:1">
          <span class="pc body4-medium">{{ item.name }}</span>
          <span class="mobile body5-medium">{{ item.name }}</span>
        </div>
        <div class="flex-align">
          <div class="main"><span class="price body4-bold">{{ item.price.price|currencyNum }}</span>원</div>
          <i v-if="visibleDelete" class="material-icons sub4" style="font-size:17px;margin-left:8px" @click="clickDelete(item)">close</i>
        </div>
      </div>
      <template v-if="list.length===0">
        <div class="pc">
          <div class="dr-item unselect" @click="$emit('add')">
            <img src="/static/img/ic_folder.svg">
            <div class="body4-medium sub3" style="margin-left:8px">플러그인 추가하기</div>
          </div>
        </div>
        <div class="mobile body6 sub4" style="margin-top:14px">플러그인이 없습니다. PC에서 추가하실 수 있습니다.</div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "DrTemporay",
    props : {
      list: {
        type: Array
      },
      visibleDelete: {
        type: Boolean,
        default: true
      }
    },
    created() {

    },
    data() {
      return {
        toggle: true
      }
    },
    methods: {
      clickDelete(item) {
        this.$emit('delete', item);
      }
    },
    computed: {
      title() {
        let title = this.list.map(i => { return i.name }).toString().replace(/,/gi, ' / ');
        if(title === '') {
          title = '없음';
        }
        return title;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .dr-container
    border 1px solid $gray1
    border-radius 8px
    padding 15px

  .dr-title
    display flex
    align-items center

  .dr-content
    border-top 1px solid $main
    margin-top 16px

  .dr-item
    padding 12px 0
    border-bottom 1px solid $gray2
    display flex
    align-items center
  .dr-item:last-child
    border-bottom 0
    padding-bottom 0
  @media (max-width:1024px)
    .dr-container
      padding 10px 12px
</style>
